// src/slices/articleSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the shape of an individual article
interface Article {
  id: number;
  title: string;
  content: string;
  articleId: number;
  articleGroupId: number;
  articleGroupName: string;
  articleName: string;
  articleDescription: string;
  icon: string;
  iconType: number;
  isPublic: number;
  isSharedInSocialMedia: number;
  isActive: string;
  attachmentURL: string;
  isPublishInNews: number;
}

// Define the shape of the slice state
interface ArticleState {
  articles: Article[]; // State contains an array of Article objects
}

// Initialize the state with an empty array of articles
const initialState: ArticleState = {
  articles: [],
};

// Create the slice
const articleSlice = createSlice({
  name: "article", // Name of the slice
  initialState, // Initial state of the slice
  reducers: {
    // Reducer to add a new article to the state
    setArticle: (state, action: PayloadAction<Article>) => {
      state.articles.push(action.payload); // Add the new article to the articles array
    },
    // Reducer to reset the articles array to an empty state
    resetArticle: (state) => {
      state.articles = []; // Clear the articles array
    },
  },
});

// Export the actions generated by createSlice
export const { setArticle, resetArticle } = articleSlice.actions;

// Export the reducer to be used in the store
export default articleSlice.reducer;
